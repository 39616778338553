import React, { useState } from "react"

import { Container, Modal, useMediaQuery, useTheme } from "@material-ui/core"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import { EffectFade, Navigation } from "swiper"
import { StaticImage } from "gatsby-plugin-image"
import LinkApp from "../linkApp"
import { useTranslation } from "react-i18next"
import "./lenderStory.scss"

function LenderStory() {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  const [openVideo, setOpenVideo] = useState(false)
  const handleOpenVideo = () => {
    setOpenVideo(true)
  }

  const handleCloseVideo = () => {
    setOpenVideo(false)
  }

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  return (
    <Container>
      <div className="asetku-lender-story">
        <div className="video-section">
          <Swiper
            slidesPerView={1}
            className="mySwiper video-swiper"
            onInit={swiper => {
              swiper.params.navigation.prevEl = ".swiper-button-prev-video"
              swiper.params.navigation.nextEl = ".swiper-button-next-video"
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            effect={"fade"}
            modules={[Navigation, EffectFade]}
          >
            <SwiperSlide>
              <div className="section-container">
                <div className="play-button">
                  <div
                    type="button"
                    onClick={handleOpenVideo}
                    onKeyDown={handleOpenVideo}
                    aria-hidden="true"
                    onMouseOver={() => {
                      setIsHover(true)
                    }}
                    onFocus={() => {
                      setIsHover(true)
                    }}
                    onMouseOut={() => {
                      setIsHover(false)
                    }}
                    onBlur={() => {
                      setIsHover(false)
                    }}
                  >
                    {isHover ? (
                      <StaticImage
                        src={"../../assets/images/icons/play_1.png"}
                        className="play-icon"
                        alt="button"
                      />
                    ) : (
                      <StaticImage
                        src={"../../assets/images/icons/play_2.png"}
                        className="play-icon"
                        alt="button"
                      />
                    )}
                  </div>
                </div>
                {isDesktop ? (
                  <StaticImage
                    src={"../../assets/images/picture/home/video/video-1.png"}
                    className="thumbnail"
                    alt="video"
                  />
                ) : (
                  <StaticImage
                    src={
                      "../../assets/images/picture/home/video/video-1-phone.png"
                    }
                    className="thumbnail"
                    alt="video"
                  />
                )}
                <Modal open={openVideo} onClose={handleCloseVideo}>
                  <div className="video-modal">
                    <video
                      src="https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/official/vUoX3GGlEmPQAjodvXUCYR7yzAlKGvyFbGhDYsHJVNk.mp4"
                      autoPlay={true}
                      className="video"
                      controls={true}
                    />
                  </div>
                </Modal>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="video-description">
          <div className="video-description-container">
            <div className="title">
              <h1>{t(`home.video-1-title`)}</h1>
              <span>{t(`home.video-1-description`)}</span>
            </div>

            <LinkApp />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LenderStory
